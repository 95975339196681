<template>
  <section class="beyond-the-code-section">
    <h2 class="section-title">Beyond the Code</h2>
    <div class="beyond-the-code-container">

      <div class="beyond-the-code-category">
        <div class="beyond-the-code-items">

          <div class="beyond-the-code-item" role="tooltip" data-microtip-position="top" aria-label="Family">
            <span class="nf nf-fa-group"></span>
          </div>
          <div class="beyond-the-code-item" role="tooltip" data-microtip-position="top" aria-label="Music">
            <span class="nf nf-cod-music"></span>
          </div>
          <div class="beyond-the-code-item" role="tooltip" data-microtip-position="top" aria-label="Guitar">
            <span class="nf nf-fa-guitar"></span>
          </div>
          <div class="beyond-the-code-item" role="tooltip" data-microtip-position="top" aria-label="to cook">
            <span class="nf nf-md-chef_hat"></span>
          </div>
          <div class="beyond-the-code-item" role="tooltip" data-microtip-position="top" aria-label="Electronics">
            <span class="nf nf-md-electric_switch"></span>
          </div>
          <div class="beyond-the-code-item" role="tooltip" data-microtip-position="top" aria-label="Photography">
            <span class="nf nf-fa-camera"></span>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>

export default {
  name: 'BeyondTheCode',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import 'microtip/microtip.css';

.beyond-the-code-section {
  padding: 2rem;
  background-color: var(--charcoal);
  margin: 2rem 0;
}

.beyond-the-code-section h2 {
  color: var(--beige);
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.beyond-the-code-container {
  display: grid;
  gap: 2rem;
}

.beyond-the-code-category {
  background-color: var(--black);
  padding: 1.5rem;
  border: 1px solid var(--taupe);
}

.beyond-the-code-items {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.beyond-the-code-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.beyond-the-code-item .nf {
  font-size: 2.5rem;
  color: var(--soft-green);
  transition: all 0.3s ease;
  margin-right: 1rem;

}

/* Hover effects */
.beyond-the-code-item:hover {
  transform: translateY(-2px);
}

.beyond-the-code-item:hover .nf {
  color: var(--beige);
}

/* Responsive Design */
@media (min-width: 768px) {
  .beyond-the-code-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

</style>
