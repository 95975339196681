<template>
  <div class="app">
    <header>
      <h1>Philippe Aellig</h1>
      <p class="subtitle">Crafting Code & Ideas</p>
    </header>

    <main>
      <WebLinks />
      <TechStack />
      <SideProjects />
      <BeyondTheCode />
    </main>

    <footer class="made-with-love">
      Made with <span class="nf nf-cod-heart"></span> in Frutigen •
      <span class="support" role="tooltip" aria-label="Slava Ukraini!" data-microtip-position="top">Слава Україні!</span>
    </footer>
  </div>
</template>

<script>
import TechStack from "@/components/TechStack.vue";
import WebLinks from "@/components/WebLinks.vue";
import SideProjects from "@/components/SideProjects.vue";
import BeyondTheCode from "@/components/BeyondTheCode.vue";


export default {
  name: 'App',
  components: {
    TechStack,
    WebLinks,
    SideProjects,
    BeyondTheCode
  }
}
</script>

<style>
@import '@/assets/fonts/webfont.css';

@font-face {
  font-family: 'JetBrainsMono Nerd Font';
  src: url('@/assets/fonts/JetBrainsMonoNerdFont-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  /* Base colors */
  --black: #1a1a1a;        /* Deep black, good for main backgrounds */
  --charcoal: #2d2d2d;     /* Soft black, good for secondary backgrounds */
  --grey: #666666;         /* Neutral grey, perfect for subtle elements */

  /* Light neutrals */
  --beige: #e6e1d6;        /* Warm beige, good for content areas */
  --taupe: #9f8e7f;        /* Sophisticated taupe, great for borders */

  /* Accent colors */
  --pale-blue: #d1d9e6;    /* Soft blue, good for highlights */
  --soft-green: #789a77;   /* Muted green, perfect for accents */

  /* Semantic assignments */
  --bg-primary: var(--black);
  --bg-secondary: var(--charcoal);
  --text-primary: var(--beige);
  --text-secondary: var(--pale-blue);
  --border-color: var(--taupe);
  --accent-primary: var(--soft-green);
  --accent-secondary: var(--grey);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-family: 'JetBrainsMono Nerd Font', monospace;
  line-height: 1.6;
}

.app {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

header {
  margin-bottom: 4rem;
  text-align: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: var(--accent-primary);
}

.subtitle {
  font-size: 1.2rem;
  opacity: 0.8;
}

section {
  margin-bottom: 3rem;
}

h2 {
  color: var(--accent-primary);
  margin-bottom: 1rem;
}

</style>
