<template>
  <section class="sideproject-section">
    <h2 class="section-title">Side Projects</h2>
    <div class="sideproject-container">

      <div class="sideproject-category">
        <div class="sideproject-items">
          <a class="sideproject-item" role="tooltip" data-microtip-position="top" aria-label="Ferret"
             target="_blank"
             href="https://ferret.aelligs.ch"
             rel="noopener noreferrer"
          >
            <span class="nf nf-md-guitar_pick_outline"></span>
          </a>
          <a class="sideproject-item" role="tooltip" data-microtip-position="top" aria-label="Gitarrenlehrer"
             target="_blank"
             href="https://www.gitarrenlehrer.ch"
             rel="noopener noreferrer"
          >
            <span class="nf nf-fa-guitar"></span>
          </a>
          <a class="sideproject-item" role="tooltip" data-microtip-position="top" aria-label="Kiev Kids"
             target="_blank"
             href="https://kievkids.ch"
             rel="noopener noreferrer"
          >
            <span class="nf nf-fa-child"></span>
          </a>
          <a class="sideproject-item" role="tooltip" data-microtip-position="top" aria-label="LaTable"
             target="_blank"
             href="https://latable.ch"
             rel="noopener noreferrer"
          >
            <span class="nf nf-md-food_fork_drink"></span>
          </a>
          <a class="sideproject-item" role="tooltip" data-microtip-position="top" aria-label="Svitlo"
             target="_blank"
             href="https://svitlo.ch"
             rel="noopener noreferrer"
          >
            <span class="nf nf-cod-gift"></span>
          </a>
        </div>
      </div>

    </div>
  </section>
</template>

<script>

export default {
  name: 'SideProjects',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import 'microtip/microtip.css';

.sideproject-section {
  padding: 2rem;
  background-color: var(--charcoal);
  margin: 2rem 0;
}

.sideproject-section h2 {
  color: var(--beige);
  margin-bottom: 2rem;
  font-size: 1.8rem;
}


.sideproject-container {
  display: grid;
  gap: 2rem;
}

.sideproject-category {
  background-color: var(--black);
  padding: 1.5rem;
  border: 1px solid var(--taupe);
}

.sideproject-items {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.sideproject-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.sideproject-item .nf {
  font-size: 2.5rem;
  color: var(--soft-green);
  margin-right: 1rem;
  transition: all 0.3s ease;
}

/* Hover effects */
.sideproject-item:hover {
  transform: translateY(-2px);
}

.sideproject-item:hover .nf {
  color: var(--beige);
}


/* Responsive Design */
@media (min-width: 768px) {
  .sideproject-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

</style>
