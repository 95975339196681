<template>
  <section class="tech-stack">
    <h2 class="section-title">Knowledge Landscape</h2>
    <div class="stack-container">

      <div class="stack-category">
        <div class="stack-items">
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Team Leadership">
            <span class="nf nf-md-account_group"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="German and English">
            <span class="nf nf-md-translate"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Project Management">
            <span class="nf nf-md-clipboard_check"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Client Communication">
            <span class="nf nf-md-comment_multiple"></span>
          </div>
        </div>
      </div>

      <div class="stack-category">
        <div class="stack-items">
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Requirements Analysis">
            <span class="nf nf-md-handshake"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Resource Planning">
            <span class="nf nf-md-chart_bar"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Kanban">
            <span class="nf nf-md-trello"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Time Management">
            <span class="nf nf-fa-clock_o"></span>
          </div>
        </div>
      </div>

      <div class="stack-category">
        <div class="stack-items">
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Magento">
            <span class="nf nf-dev-magento"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="WordPress">
            <span class="nf nf-dev-wordpress"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Joomla">
            <span class="nf nf-fa-joomla"></span>
          </div>
        </div>
      </div>
      <div class="stack-category">
        <div class="stack-items">
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="PHP">
            <span class="nf nf-dev-php"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="JavaScript">
            <span class="nf nf-dev-javascript"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="CSS">
            <span class="nf nf-dev-css3"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Vue">
            <span class="nf nf-md-vuejs"></span>
          </div>
        </div>
      </div>


      <div class="stack-category">
        <div class="stack-items">
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Symfony">
            <span class="nf nf-dev-symfony"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Laravel">
            <span class="nf nf-dev-laravel"></span>
          </div>
        </div>
      </div>

      <div class="stack-category">
        <div class="stack-items">
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Linux">
            <span class="nf nf-dev-linux"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="Docker">
            <span class="nf nf-dev-docker"></span>
          </div>
          <div class="stack-item" role="tooltip" data-microtip-position="top" aria-label="nginx">
            <span class="nf nf-dev-nginx"></span>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>

export default {
  name: 'TechStack',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import 'microtip/microtip.css';

.tech-stack {
  padding: 2rem;
  background-color: var(--charcoal);
  margin: 2rem 0;
}

.tech-stack h2 {
  color: var(--beige);
  margin-bottom: 2rem;
  font-size: 1.8rem;
}


.stack-container {
  display: grid;
  gap: 2rem;
}

.stack-category {
  background-color: var(--black);
  padding: 1.5rem;
  border: 1px solid var(--taupe);
}

.stack-items {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.stack-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.stack-item .nf {
  font-size: 2.5rem;
  color: var(--soft-green);
  margin-right: 1rem;
  transition: all 0.3s ease;
}

/* Hover effects */
.stack-item:hover {
  transform: translateY(-2px);
}

.stack-item:hover .nf {
  color: var(--beige);
}

/* Responsive Design */
@media (min-width: 768px) {
  .stack-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

</style>
