<template>
  <section class="links">
    <div class="links-container">
      <div class="link-item">
        <a href="mailto:philippe@aelligs.ch" rel="noopener noreferrer">
          <span class="nf nf-md-email"></span>
          <span class="label">philippe@aelligs.ch</span>
        </a>
      </div>
      <div class="link-item">
        <a href="https://www.weloveyou.ch" target="_blank" rel="noopener noreferrer">
          <span class="nf nf-cod-link_external"></span>
          <span class="label">WeLoveYou.ch</span>
        </a>
      </div>
      <div class="link-item">
        <a href="https://www.woodern.ch" target="_blank" rel="noopener noreferrer">
          <span class="nf nf-cod-link_external"></span>
          <span class="label">woodern.ch</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WebLinks',
}
</script>

<style scoped>
.links {
  padding: 2rem;
  background-color: var(--charcoal);
  margin: 2rem 0;
}

.links h2 {
  color: var(--beige);
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.links-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: var(--black);
  padding: 1.5rem;
  border: 1px solid var(--taupe);
}

.link-item {
  transition: transform 0.2s ease;
}

.link-item a {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: var(--beige);
}

.link-item .nf {
  font-size: 1.5rem;
  color: var(--soft-green);
}

.link-item .label {
  font-size: 1rem;
}

/* Responsive Design */
@media (min-width: 768px) {
  .links-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
</style>